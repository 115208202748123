import {combineReducers} from "redux"
import reduxifyServices from "feathers-redux"
import {client, authentication} from "../feathers"

const services = reduxifyServices(client, ['users']);
const reducers = {
    authentication: authentication.reducer,
    user: services.users.reducer
};

export const rootReducer = combineReducers(reducers);